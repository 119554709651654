import React from "react"
import {
  OurProductSection,
  ProductsBtn,
  ProductsHeading,
  ProductsHeadingWrapper,
  ProductsInner,
  ProductsLeft,
  ProductsLeftHeading,
  ProductsRight,
  ProductsRightHeading,
  ProductsRow,
  ProductsRowReverse,
} from "./FeaturesImageVideoVersion.style"
import { Col, Container, Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import Fade from "react-reveal/Fade"
import ButtonDropDown from "../ButtonDropDown"
import "./custom.css"
import {
  ProductsLeftImgFirst,
  ProductsLeftImgSecond,
} from "../FeaturesImageVersion/FeatureImageVersion.style"

const FeaturesImageVideoVersion = ({ data }) => {
  const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF"

  return (
    <OurProductSection
      id="featuresImageVideoVersionContainer"
      style={{ background: color }}
    >
      <Container>
        {data.FeaturesHeading ? (
          <Row>
            <Col md="12">
              <ProductsHeadingWrapper>
                <Fade bottom delay={1 * data.DelayConstant}>
                  <ProductsHeading>{data.FeaturesHeading}</ProductsHeading>
                </Fade>
                <Fade bottom delay={2 * data.DelayConstant}>
                  <h6>{data.FeaturesSubHeading}</h6>
                </Fade>
              </ProductsHeadingWrapper>
            </Col>
          </Row>
        ) : null}

        <ProductsInner>
          {data.Features.map((item, idx) => {
            let videoId = item.FeaturesVideo?.split("/").pop()
            return item.FeaturesVideoPositionLeft ? (
              <ProductsRow className="customPadding" key={idx}>
                <Col md="6">
                  <Fade left delay={1 * data.DelayConstant}>
                    <ProductsRight style={{ textAlign: "left", paddingBottom: "16rem" }}>
                      {videoId ? (
                        <iframe
                          src={item.FeaturesVideo}
                          title={idx}
                          frameBorder="0"
                          border="0"
                          cellspacing="0"
                          className="iframeMobile"
                          style={{
                            borderStyle: "none",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 26px 5px #d0d0d0",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%"
                          }}
                          srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${
                            item.FeaturesVideo
                          }?autoplay=1><img src=${
                            item.FeaturesVideoThumbnail &&
                            item.FeaturesVideoThumbnail !== ""
                              ? item.FeaturesVideoThumbnail
                              : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
                          } alt='${idx}'><span>▶</span></a>`}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <>
                          <ProductsLeftImgFirst src={item.Image1} alt="" />
                          <ProductsLeftImgSecond src={item.Image2} alt="" />
                        </>
                      )}
                    </ProductsRight>
                  </Fade>
                </Col>
                <Col md="6">
                  <ProductsLeft>
                    <Fade right delay={1 * data.DelayConstant}>
                      <ProductsRightHeading>
                        {item.FeaturesHeading}
                      </ProductsRightHeading>
                    </Fade>
                    <Fade right delay={2 * data.DelayConstant}>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {item.FeaturesDescription}
                      </p>
                    </Fade>
                    <Fade right delay={3 * data.DelayConstant}>
                      {item.Button.MultiSelection ? (
                        <ButtonDropDown data={item.Button} />
                      ) : (
                        <ProductsBtn
                          href={item.Button.Items[0].Href}
                          target="_blank"
                        >
                          {item.Button.Title}
                        </ProductsBtn>
                      )}
                    </Fade>
                  </ProductsLeft>
                </Col>
              </ProductsRow>
            ) : (
              <ProductsRowReverse className="customPadding" key={idx}>
                <Col md="6">
                  <Fade right delay={1 * data.DelayConstant}>
                    <ProductsRight>
                      {item.FeaturesVideo ? (
                        <iframe
                          src={item.FeaturesVideo}
                          title={idx}
                          frameborder="0"
                          border="0"
                          cellspacing="0"
                          className="iframeMobile"
                          style={{
                            borderStyle: "none",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 26px 5px #d0d0d0",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                          }}
                          srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${
                            item.FeaturesVideo
                          }?autoplay=1><img src=${
                            item.FeaturesVideoThumbnail &&
                            item.FeaturesVideoThumbnail !== ""
                              ? item.FeaturesVideoThumbnail
                              : `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
                          } alt='${idx}'><span>▶</span></a>`}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <>
                          <ProductsLeftImgFirst src={item.Image1} alt="" />
                          <ProductsLeftImgSecond src={item.Image2} alt="" />
                        </>
                      )}
                    </ProductsRight>
                  </Fade>
                </Col>
                <Col md="6">
                  <ProductsLeft>
                    <Fade left delay={1 * data.DelayConstant}>
                      <ProductsLeftHeading>
                        {item.FeaturesHeading}
                      </ProductsLeftHeading>
                    </Fade>
                    <Fade left delay={2 * data.DelayConstant}>
                      <p style={{ whiteSpace: "pre-wrap" }}>
                        {item.FeaturesDescription}
                      </p>
                    </Fade>
                    <Fade left delay={3 * data.DelayConstant}>
                      {item.Button.MultiSelection ? (
                        <ButtonDropDown data={item.Button} />
                      ) : (
                        <ProductsBtn
                          href={item.Button.Items[0].Href}
                          target="_blank"
                        >
                          {item.Button.Title}
                        </ProductsBtn>
                      )}
                    </Fade>
                  </ProductsLeft>
                </Col>
              </ProductsRowReverse>
            )
          })}
        </ProductsInner>
      </Container>
    </OurProductSection>
  )
}
export default FeaturesImageVideoVersion
