import React from 'react';
import {
    PricingSection, PricingHeadingWrapper, PricingHeading,
    PricingPlanCol, PricingSpan,
    PricingPlanLine, PricingPlanBtn, PricingPlanLightText, PricingPlanDarkText
} from './pricing.style';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const PricingCustom = ({ data }) => {
    const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF";

    return (
        <PricingSection id="pricingDynamicContainer"
            backgroundColor={data.BackgroundColor}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", background: color }}>
            <Container>


                <Row>
                    <Col md="12">
                        <PricingHeadingWrapper>
                            <Fade bottom delay={1 * data.DelayConstant}>
                                <PricingHeading>
                                    {data.PricingHeading}
                                </PricingHeading>
                            </Fade>
                            <Fade bottom delay={2 * data.DelayConstant}>
                                <p>
                                    {data.PricingSubHeading}
                                </p>
                            </Fade>
                        </PricingHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                    {
                        data.PricingPlanCol.map((item, idx) => {
                            return <Col>
                                <Fade bottom delay={(idx + 1) * data.DelayConstant}>
                                    <PricingPlanCol>
                                        <h3>{data.PricingPlanCol[idx].PricingPlanHeading}</h3>

                                        <h1>{data.PricingPlanCol[idx].PricingPlanPrice} / <PricingSpan>{data.PricingPlanCol[idx].PricingPlanPriceSpan}</PricingSpan></h1>
                                        <PricingPlanLine />
                                        {
                                            data.PricingPlanCol[idx].PricingPlanDarkText?.map((itemDark, idxDark) => {
                                                return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                            })
                                        }
                                        {
                                            data.PricingPlanCol[idx].PricingPlanLightText?.map((itemLight, idxLight) => {
                                                return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                            })
                                        }
                                        <PricingPlanBtn href={data.PricingPlanCol[idx].PricingPlanBtnHref}>{data.PricingPlanCol[idx].PricingPlanBtn}</PricingPlanBtn>
                                    </PricingPlanCol>
                                </Fade>
                            </Col>

                        })
                    }

                </Row>
            </Container>
        </PricingSection>
    );
}

export default PricingCustom;
