import React, { useState } from 'react'
import BannerSectionCustom from '../containers/CustomContainers/Banner'
import FooterCustom from '../containers/CustomContainers/Footer'
import HeadermenuCustom from '../containers/CustomContainers/HeadermenuBs'
import ContactPoc from '../containers/CustomContainers/Contact'
import BlogPosts from '../containers/CustomContainers/BlogPosts'
import Products from '../containers/CustomContainers/Products'
import Pricing from '../containers/CustomContainers/Pricing-Custom'
import TextDescription from '../containers/CustomContainers/SimpleTextDescription'
import KeyFeatures from '../containers/CustomContainers/KeyFeatures'
import CookieContainer from '../containers/CustomContainers/CookieContainer'
import GlobalStyle from '../containers/CustomContainers/Common/global-styles'
import '../components/layout.css'
import Seo from '../components/seo'
import Fonts from '../containers/CustomContainers/Common/fonts'
import { graphql } from 'gatsby'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import FeaturesImageVideoVersion from '../containers/CustomContainers/FeaturesImageVideoVersion'
import Partners from '../containers/CustomContainers/Partners'

const PBIPortal = ({ data }) => {

    const AppName = 'PowerBI Portal'
    const SharedData = data.sharedJson
    const pbiPortalJson = data.pbiPortalJson
    const [blocking, setBlocking] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const partners = data.allPowerBiTilesPowerBiTilesPartnersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }
        return content
    })

    return (
        <BlockUi tag="div" blocking={blocking} message="Submiting your message" keepInView>

            <React.Fragment>
                <div>
                    <Fonts/>
                    <GlobalStyle/>
                    <HeadermenuCustom data={pbiPortalJson.Header} expanded={expanded}
                                      expandToggle={(newValue) => setExpanded(newValue)}/>
                    <main onClick={() => setExpanded(false)}>
                        <Seo
                            title={pbiPortalJson.SEO.Title}
                            description={pbiPortalJson.SEO.Description}
                            favIconPath={pbiPortalJson.SEO.FavIconPath}
                            previewImage={pbiPortalJson.SEO.PreviewImage}
                            keywords={pbiPortalJson.SEO.Keywords}

                        />
                        <BannerSectionCustom data={pbiPortalJson.Banner}/>
                        <TextDescription data={pbiPortalJson.TextDescription}/>
                        <FeaturesImageVideoVersion data={pbiPortalJson.FeaturesImageVideoVersion}/>


                        <KeyFeatures data={pbiPortalJson.KeyFeatures}/>

                        <Pricing data={pbiPortalJson.Pricing} />

                        <TextDescription data={pbiPortalJson.PowerBIEmbeddedTextDescription} />

                        <Products sharedData={SharedData.Products} data={pbiPortalJson.Products}/>

                        <BlogPosts data={pbiPortalJson.BlogPosts}/>
                        <ContactPoc sharedData={SharedData.ContactUs} data={pbiPortalJson.ContactUs}
                                    blockUIChange={(newValue) => setBlocking(newValue)} appName={AppName}/>

                        <Partners data={partners}/>

                    </main>
                    <FooterCustom data={pbiPortalJson.Footer}/>
                </div>
                <CookieContainer sharedData={SharedData.Cookie}/>

            </React.Fragment>

        </BlockUi>

    )

}

export const pageQuery = graphql`
    query portalPageQuery {
        allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(PBIPortal/FAQS)/"}}
            sort: {order: ASC, fields: [frontmatter___category, frontmatter___path]}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        category
                    }
                    html
                }
            }
        }
        allPowerBiTilesPowerBiTilesPartnersListItem(
            sort: {order: ASC, fields: [data___fields___ColumnOrder]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
        sharedJson {
            Products {
                Items {
                    ProductsCardIcon
                    ProductsCardHeading
                    ProductsCardPara
                    ProductRoute
                }
            }
            ContactUs{
                SectionHeading
                SectionSubHeading
                ContactRight{
                    ContactRightHeading
                    ContactRightPara
                }
            }
            Cookie{
                CookieName
                CookieExpireDays
                SaveCookieOnDecline
                OkButtonMessage
                CookieMessage
            }
        }
        pbiPortalJson
        {
            SEO {
                Title
                Description
                FavIconPath
                PreviewImage
                Keywords
            }
            Header{
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems{
                    Menu
                    Href
                    LocalLink
                }
            }
            Banner{
                BannerHeading
                BannerDescription
                WatchVideoAnchor
                BtnText {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                BtnText2 {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                PathToImage
            }
            TextDescription {
                BackgroundGrey
                Heading
                Border
                Description
            }
            FeaturesImageVideoVersion {
                BackgroundGrey
                FeaturesSubHeading
                FeaturesHeading
                Features {
                    FeaturesVideo
                    FeaturesVideoThumbnail
                    FeaturesVideoPositionLeft
                    FeaturesHeading
                    FeaturesDescription
                    Image1
                    Image2
                    Button {
                        Title
                        Items {
                            Name
                            Href
                        }
                        MultiSelection
                    }
                }
                DelayConstant
            }
            KeyFeatures {
                BackgroundGrey
                DelayConstant
                KeyFeaturesHeading
                KeyFeaturesPara
                KeyFeaturesItems {
                    KeyFeaturesTitle
                    KeyFeaturesDescription
                }
            }
            Pricing {
              BackgroundGrey
              DelayConstant
              PricingHeading
              PricingSubHeading
              PricingPlanCol {
                PricingPlanBtn
                PricingPlanBtnHref
                PricingPlanDarkText {
                  Text
                }
                PricingPlanHeading
                PricingPlanImg
                PricingPlanPrice
                PricingPlanPriceSpan
              }
            }
            PowerBIEmbeddedTextDescription {
                BackgroundGrey
                Heading
                Border
                Description
            }
            Products {
                BackgroundGrey
                DelayConstant
                ProductsLeftHeading
                ProductsLeftPara
                ProductsLeftBtn {
                    Title
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
            }
            BlogPosts{
                BackgroundGrey
                SectionHeading

                BlogImg1
                BlogName1
                BlogDesc1
                DetailsText1
                Blog1Button {
                    Name
                    Href
                }

                BlogImg2
                BlogName2
                BlogDesc2
                DetailsText2
                Blog2Button {
                    Name
                    Href
                }

                BlogImg3
                BlogName3
                BlogDesc3
                DetailsText3
                Blog3Button {
                    Name
                    Href
                }
            }
            Footer {
                FooterLogo
                Items
                FooterCol2{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                        LocalLink
                    }
                }
                FooterCol3{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                    }
                }
                FooterCol4{
                    FooterTitle
                    FooterAddWrapper{
                        FooterAddImg
                        FooterAddText
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                SocialLinks{
                    FacebookLink
                    TwitterLink
                    LinkedinLink
                    InstagramLink
                }
            }
            ContactUs {
                BackgroundGrey
            }
        }
    }
`

export default PBIPortal
