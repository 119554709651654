import styled from 'styled-components';
import { Commonbtn, SectionHeading } from '../../CustomContainers/Common/common.style';
import { device } from '../../CustomContainers/Common/device';

export const PricingSection = styled.div`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const PricingHeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
    p{
        margin-bottom: 26px;
        font-size: 16px;
        text-align:Center;
    }
`;

export const PricingHeading = styled(SectionHeading)`
`;



export const PricingPlanCol = styled.div`
    background:#fff;
    border-radius: 10px;
    text-align:center;
    padding:50px 25px;
    border-top:3px solid #5f27fa;
    box-shadow: 0px 0px 10px 0px #d8d8d8;
    margin:40px 0px 0px;

    @media ${device.tablet} {
        margin:0px;
    }
    img{
        width:80px;
        height:80px;
        margin-bottom:25px;
    }
    h3{
        text-align: center;
        font-weight: 500;
        color: #5f27fa;
        margin-bottom: 30px;
    }
    h1{
        margin-bottom:30px;
    }
`;

export const PricingPlanColCenter = styled.div`
    background:#5f27fa;
    border-radius: 10px;
    text-align:center;
    padding:60px 25px;
    border-top:3px solid #5f27fa;
    box-shadow: 0px 0px 10px 0px #d8d8d8;
    padding-top:70px;
    padding-bottom:70px;

    @media ${device.tablet} {
        margin:30px 0px;
    }
    img{
        width:80px;
        height:80px;
        margin-bottom:25px;
    }
    h3{
        color:#fff;
    }
    h1{
        color:#fff;
    }
`;

export const PricingPlanCenterHeading = styled.h3`
    color:#fff;
    margin-bottom:30px;
`;

export const PricingPlanCenterPrice = styled.h2`
    color:#fff;
    margin-bottom:30px;
`;

export const PricingPlanCenterLine = styled.hr`
    background: #7e50ff;
`;

export const PricingPlanCenterText = styled.p`
    font-weight:500;
    margin-bottom:5px;
    color:#fff;
`;

export const PricingPlanHeading = styled.h3`
    color:#5f27fa;
    margin-bottom:30px;
`;


export const PricingSpan = styled.span`
    font-size:30px;
`;

export const PricingPlanLine = styled.hr`
    background: #ece5ff;
`;

export const PricingPlanDarkText = styled.p`
    font-weight:500;
    margin-bottom:5px;
`;

export const PricingPlanLightText = styled.p`
    color:#b8b8b8;
    margin-bottom:5px;
    font-weight:500;
`;

export const PricingPlanBtn = styled(Commonbtn)`
    margin-top: 30px;
`;

export const PricingPlanBtnCenter = styled(Commonbtn)`
    border:1px solid #fff;
    margin-top: 30px;
    background:none;

    :before{
        background:#fff;
    }

    :hover{
        color:#000;
    }
`;
